<script lang="ts" setup>
import type { ErrorObject } from '@consumer/composables/validation'
import type { ComputedRef } from 'vue'

withDefaults(defineProps < {
  error?: any
}>(), {  })

const slots = useSlots()

const hasError = computed(() => slots.default || errorMessage.value.length > 0)

const isErrorObject = (obj: any): obj is ErrorObject => {
  return obj !== undefined && (obj as ErrorObject).$message !== undefined
}

const errorMessage: ComputedRef<string[]> = computed(() => {
  if (!__props.error) {
    return []
  }

  if (Array.isArray(__props.error)) {
    return __props.error.map(item => String(isErrorObject(item) ? item?.$message : item))
  }
  else {
    return [String(isErrorObject(__props.error) ? __props.error?.$message : __props.error)]
  }
})
</script>

<template>
  <div v-if="hasError" class="mt-2 text-red-base space-y-2">
    <slot>
      <div v-for="(message, i) in errorMessage" :key="i" class="text-red-base">
        {{ message }}
      </div>
    </slot>
  </div>
</template>

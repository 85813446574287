import { checkout as checkoutApi } from '@consumer/api'
import { useVuelidate } from '@vuelidate/core'
import { helpers, sameAs, and } from '@vuelidate/validators'

export type { ErrorObject } from '@vuelidate/core'

// helper functions

export { and }
export const withAsync = helpers.withAsync
export const withMessage = helpers.withMessage
export { sameAs }

// validator functions

export const required = helpers.withMessage(
  'This field cannot be empty',
  (value?: any) => typeof value === 'boolean' ? value : helpers.req(value),
)

function isValidEmail (email?: string) {
  const emailRegex
  // eslint-disable-next-line no-useless-escape
  = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return emailRegex.test(String(email))
}

export async function email (email: string, staticValidation = false): Promise<boolean> {
  if (!helpers.req(email)) { return true }

  const isValid = isValidEmail(email)

  if (staticValidation === true || !isValid) { return isValid }

  try {
    return await checkoutApi.validateEmail({ data: { email } }).then(({ result }) => result)
  }
  catch {
    return isValid
  }
}

export const requiredEmail = withMessage('Please enter a valid email address', and(withAsync(email), required))

export const phoneNumber = (number?: string) => helpers.req(number) && number?.replace(/[^0-9]/g, '').length === 10

export const requiredPhoneNumber = withMessage('Please enter a valid phone number', and(phoneNumber, required))

export const zipCode = (number?: string) => !!number?.match(/^\d{5}$/)

export const internationalPhoneNumber = (number?: string) => helpers.req(number)
  && (number ?? '')?.replace(/[^0-9]/g, '').length >= 10

export const requiredInternationalPhoneNumber = withMessage(
  'Please enter a valid phone number',
  and(internationalPhoneNumber, required),
)

// composable function

export const useValidation = useVuelidate

import type { ErrorObject } from '@vuelidate/core'
import { useActiveElement } from '@vueuse/core'
import type { Placement } from 'floating-vue'
import type { ComputedRef, Ref, MaybeRefOrGetter } from 'vue'

export const useFieldWithErrors = (
  fieldEl: null | Ref<any>,
  errorRef: MaybeRefOrGetter<any> | undefined,
  errorTooltipRef: MaybeRefOrGetter<boolean | Boolean | Placement | undefined>,
  hasError?: MaybeRefOrGetter<boolean>,
) => {
  // Set to true on keypress, not on focus, to show that the user has attempted to set this field
  const activated = ref(false)
  const activate = () => activated.value = true

  const activeElement = useActiveElement()

  const showError = computed(() => (
    Boolean(
      ((activated.value && errorMessages.value.length > 0) || toValue(hasError) || toValue(errorRef)?.length)
      && (fieldEl?.value as HTMLElement) !== activeElement.value,
    )
  ))

  const isErrorObject = (obj: any): obj is ErrorObject => {
    return obj !== undefined && (obj as ErrorObject).$message !== undefined
  }

  const errorMessages: ComputedRef<string[]> = computed(() => {
    const error = toValue(errorRef)
    if (!error) return []

    if (Array.isArray(error)) {
      return error.map(item => String(isErrorObject(item) ? item?.$message : item))
    }

    return [String(isErrorObject(error) ? error?.$message : error)]
  })

  const errorTooltipPlacement = computed(() => {
    const placementOrBoolean = toValue(errorTooltipRef)
    return typeof placementOrBoolean === 'boolean' ? 'bottom-start' : placementOrBoolean as Placement || 'bottom-start'
  })

  return {
    activated,
    activate,
    showError,
    errorMessages,
    errorTooltipPlacement,
  }
}
